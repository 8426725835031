/* Global imports */
import Image from "gatsby-image";
import PropTypes from "prop-types";
import React from "react";
import ReactMarkdown from "react-markdown";

/* Local imports */

/* Component definition */
const Contact = ({ name, email, image, isMinimal, bio }) => (
  <div className="author">
    {!isMinimal && <Image className="author-photo" fluid={image} />}
    <div className="d-flex author-info">
      <p className="author-name">{name}</p>
      <a href={`mailto://${email}`} className="author-mail">
        {email}
      </a>
      {!isMinimal && bio && (
        <p className="author-bio">
          <ReactMarkdown>{bio}</ReactMarkdown>
        </p>
      )}
    </div>
  </div>
);
/* PropTypes */
Contact.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string,
  image: PropTypes.object,
  isMinimal: PropTypes.bool,
  bio: PropTypes.string,
};

Contact.defaultProps = {
  name: "Complete Name",
  email: "",
};

/* Local utility functions */

/* Styles */

export default Contact;
