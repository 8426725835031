/* Global imports */
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Contacts from "./contacts";
import SidebarNavigation from "./navigation";
import Teaser from "./teaser";
import { dtrOptions } from '../../helper/richText';

const SidebarDynamic = ({ sidebar, locale, childPages }) => {
  let { components } = sidebar;

  components =
    components &&
    components.map(component => {
      switch (component.internal.type) {
        case "ContentfulAuthor":
          return (
            <Contacts
              authors={[
                {
                  ...component,
                  image: component.photo && component.photo.fixed,
                },
              ]}
              locale={locale}
            />
          );
        case "ContentfulAdditionalInfo":
          return (
            <Teaser
              title={component.title}
              content={documentToReactComponents(component.body.json, dtrOptions)}
              link={component.link}
              locale={locale}
            />
          );
        default:
          return "";
      }
    });
  return (
    <Container fluid className={"sidebarDynamic p-0"}>
      {childPages && (
        <SidebarNavigation
          className={"mb-4"}
          childPages={childPages}
        ></SidebarNavigation>
      )}
      <Row>
        {components &&
          components.map(component => (
            <Col xs={12} md={6} lg={12}>
              {component}
            </Col>
          ))}
      </Row>
    </Container>
  );
};
SidebarDynamic.propTypes = {
  sidebar: PropTypes.object,
  locale: PropTypes.string,
  childPages: PropTypes.array,
};
SidebarDynamic.defaultProps = {
  sidebar: {},
  locale: "en-US",
  childPages: [],
};

export default SidebarDynamic;
