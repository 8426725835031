/* Global imports */
import PropTypes from "prop-types";
import React from "react";
import Link from "../../helper/link";
/* Local imports */
import Box from "./box";

/* Component definition */
const References = ({ references, locale }) => {
  return (
    <Box
      headline="labels.references"
      className="d-flex references"
      locale={locale}
    >
      {references.map(reference => (
        <div className="reference">
          <Link to={{ url: reference.link }} className="reference-link">
            {reference.title}
          </Link>
          <p className="reference-meta">{reference.metadescription}</p>
        </div>
      ))}
    </Box>
  );
};
/* PropTypes */
References.propTypes = {
  references: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      metadescription: PropTypes.string.isRequired,
    })
  ),
  locale: PropTypes.oneOf(["en-US"]),
};
References.defaultProps = {
  references: [],
  locale: "en-US",
};

/* Local utility functions */

/* Styles */

export default References;
