import * as _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import {
  Accordion,
  Card,
  Col,
  Container,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";

import AgendaItem from "../components/agendaItem";
import Hosts from "../components/hosts";
import Moderators from "../components/moderators";
import Speakers from "../components/speakers";
import Summary from "../components/summary";
import Breadcrumb from "../modules/breadcrumb";
import Layout from "../modules/layout";
import News from "../modules/news";
import SEO from "../modules/seo";
import { getValue } from "../helper/translation";
import { StringParam, useQueryParam } from "use-query-params";
import { getFormattedDate } from "../helper/date";

const PastForumsDetail = ({ data: { event }, pageContext }) => {
  const {
    agendas,
    description,
    endDate,
    id,
    location,
    startDate,
    title,
    subtitle,
    summaryContent,
  } = event;

  const {
    name,
    contentComponents,
    node_locale,
    settings,
    breadcrumbs,
    showBreadcrumbNavigation,
    showNews,
  } = pageContext;
  const siteSettings = settings;

  const { microcopy } = settings;

  const [activeTabQuery, setActiveTabQuery] = useQueryParam("r", StringParam);
  const [activeTab, setActiveTab] = React.useState();

  React.useEffect(() => {
    setActiveTab(activeTabQuery);
  }, [activeTabQuery]);

  // Create empty array for SPEAKERS
  const arrSpeakers = [];

  // Fill array with speakers of all the ssesions
  if (event) {
    if (event.agendas) {
      event.agendas.map(agenda => {
        const { agendaItems } = agenda;
        agendaItems.map(agendaItem => {
          if (agendaItem.sessions) {
            agendaItem.sessions.map(session => {
              const { speakers } = session;
              if (speakers) {
                speakers.map(speaker => {
                  arrSpeakers.push(speaker);
                });
              }
            });
          }
        });
      });
    }
  }
  const arrNullImagesSpeakers = [];
  // Push null image speakers into new array
  arrSpeakers.map(speaker => {
    if (speaker.image === null) {
      arrNullImagesSpeakers.push(speaker);
    }
  });
  // Remove them from the original array
  _.remove(arrSpeakers, speaker => speaker.image === null);

  // Remove the duplicates
  const arrUniqueSpeakers = _.uniqBy(arrSpeakers, "image.fluid.srcSet");
  // Concat all the rest of the speakers
  const arrAllUniqueSpeakers = arrUniqueSpeakers.concat(arrNullImagesSpeakers);

  // Create empty array for MODERATORS
  const arrModerators = [];

  // Fill array with speakers of all the ssesions
  if (event) {
    if (event.agendas) {
      event.agendas.map(agenda => {
        const { agendaItems } = agenda;
        agendaItems.map(agendaItem => {
          if (agendaItem.sessions) {
            agendaItem.sessions.map(session => {
              const { moderators } = session;
              if (moderators) {
                moderators.map(moderator => {
                  arrModerators.push(moderator);
                });
              }
            });
          }
        });
      });
    }
  }
  const arrNullImagesModerators = [];
  // Push null image speakers into new array
  arrModerators.map(moderator => {
    if (moderator.image === null) {
      arrNullImagesModerators.push(moderator);
    }
  });
  // Remove them from the original array
  _.remove(arrModerators, moderator => moderator.image === null);

  // Remove the duplicates
  const arrUniqueModerators = _.uniqBy(arrModerators, "image.fluid.srcSet");
  // Concat all the rest of the speakers
  const arrAllUniqueModerators = arrUniqueModerators.concat(
    arrNullImagesModerators
  );

  // Create empty array for Hosts
  const arrHosts = [];

  // Fill array with speakers of all the ssesions
  if (event) {
    if (event.agendas) {
      event.agendas.map(agenda => {
        const { agendaItems } = agenda;
        agendaItems.map(agendaItem => {
          if (agendaItem.sessions) {
            agendaItem.sessions.map(session => {
              const { hosts } = session;
              if (hosts) {
                hosts.map(host => {
                  arrHosts.push(host);
                });
              }
            });
          }
        });
      });
    }
  }
  // Remove the duplicates
  const arrUniqueHosts = _.uniqBy(arrHosts, "name");

  const summaryContentParams = {
    ...summaryContent,
    node_locale,
  };

  const changeTab = tab => {
    setActiveTabQuery(tab);
  };

  return (
    <Layout
      siteSettings={siteSettings}
      showBreadcrumbNavigation={showBreadcrumbNavigation}
      showNews={showNews}
    >
      <SEO
        title={`${siteSettings.siteName} - ${title}`}
        description={siteSettings.siteDescription.siteDescription}
        author={siteSettings.siteAuthor}
      />
      <div className="pastForumsBreadcrumb">
        <Breadcrumb
          visible={showBreadcrumbNavigation}
          breadcrumbs={breadcrumbs}
        ></Breadcrumb>
      </div>
      <Container fluid className="pastForumsPage">
        <Row>
          <Col>
            <h4>{getFormattedDate(startDate, endDate)}</h4>
            <div className="titleAndSubtitle">
              <h2>{title}</h2>
              <h4 className="font-weight-normal">{subtitle}</h4>
            </div>
            <div className="eventsContainer">
              <Tabs
                activeKey={activeTab}
                transition={false}
                onSelect={k => changeTab(k)}
                className="w-100"
              >
                <Tab
                  id="summary"
                  eventKey="summary"
                  title={getValue("labels.summary", microcopy)}
                >
                  {summaryContent && <Summary {...summaryContentParams} />}
                </Tab>
                <Tab
                  eventKey="speaker"
                  title={getValue("labels.speaker", microcopy)}
                >
                  <Moderators
                    moderators={arrAllUniqueModerators}
                    node_locale={node_locale}
                  />
                  <Speakers
                    speakers={arrAllUniqueSpeakers}
                    node_locale={node_locale}
                  />
                </Tab>
                <Tab
                  eventKey="hosts"
                  title={getValue("labels.hosts", microcopy)}
                >
                  {arrUniqueHosts && (
                    <Row clasName="hostContainer ">
                      <Hosts hosts={arrUniqueHosts} fullWidth={true} />
                    </Row>
                  )}
                </Tab>

                <Tab
                  eventKey="documentation"
                  title={getValue("labels.documentation", microcopy)}
                >
                  {/* AGENDA LOOP */}
                  {event.agendas.map((agenda, agendaIndex) => (
                    <div className="agendaContainer">
                      <Accordion
                        // defaultActiveKey={event.agendas[0].title}
                        defaultActiveKey={agenda.title}
                      >
                        <Card>
                          <Card.Header className="topCardHeader">
                            <Accordion.Toggle
                              as={Card.Header}
                              id={agenda.agendaItems.id}
                              variant="link"
                              // eventKey={tab.id}
                              eventKey={agenda.title}
                              className="cardHeaderInternalAgenda"
                            >
                              {/* {tab.label} */}
                              <div className="firstSection font-weight-bold">
                                <h3 className="font-weight-normal">
                                  {agenda.title}
                                </h3>
                              </div>
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse
                            // eventKey={tab.id}
                            eventKey={agenda.title}
                          >
                            <div className="tabs-container-programme">
                              {agenda.agendaItems.map((agendaItem, index) => {
                                const firstParams = {
                                  ...agendaItem,
                                  firstOpened: true,
                                };
                                return agendaIndex === 0 && index === 0 ? (
                                  <AgendaItem {...firstParams} />
                                ) : (
                                  <AgendaItem {...agendaItem} />
                                );
                              })}
                              <hr className="agendaitemLastDivider"></hr>
                            </div>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    </div>
                  ))}
                </Tab>

                {/* <Tab eventKey="blog" title={getValue("labels.blog", microcopy)}> */}
              </Tabs>
            </div>
          </Col>
        </Row>
      </Container>
      <News node_locale={node_locale} settings={settings} visible={showNews} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query($contentful_id: String!, $node_locale: String!) {
    event: contentfulEvent(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $node_locale }
    ) {
      id
      title
      subtitle
      description
      endDate(fromNow: false)
      startDate(fromNow: false)
      summaryContent {
        title
        node_locale
        longDescription {
          json
        }
        infoBoxes {
          ... on ContentfulInfo {
            title
            description {
              description
            }
          }
          ... on ContentfulAdditionalInfo {
            title
            body {
              json
            }
            link {
              url
              linkText
            }
            internalLink {
              slug
            }
          }
        }
        video {
          embedLink
          thumbnailLandscape {
            imageLandscape {
              fluid {
                srcSet
                src
                base64
                srcSetWebp
                srcWebp
              }
            }
          }
        }
        asideGalleryImages {
          image {
            fixed(width: 150, quality: 100) {
              src
              srcSet
              srcSetWebp
              srcWebp
              height
              width
            }
            fluid {
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
        }
      }
      agendas {
        ... on ContentfulAgenda {
          title
          agendaItems {
            ... on ContentfulAgendaItem {
              id
              title
              startTime(fromNow: false)
              endTime(fromNow: false)
              withoutSessions
              sessions {
                ... on ContentfulSession {
                  id
                  title
                  name
                  subtitle
                  mainTopic
                  secondaryTopic
                  node_locale
                  reader {
                    json
                  }
                  description {
                    description
                  }
                  speakers {
                    ... on ContentfulPerson {
                      id
                      contentful_id
                      name
                      organization {
                        name
                      }
                      image {
                        fluid {
                          base64
                          sizes
                          src
                          srcSet
                          srcWebp
                          aspectRatio
                        }
                      }
                      biography {
                        json
                      }
                    }
                  }
                  moderators {
                    ... on ContentfulPerson {
                      id
                      contentful_id
                      name
                      organization {
                        name
                      }
                      image {
                        fluid {
                          base64
                          sizes
                          src
                          srcSet
                          srcWebp
                          aspectRatio
                        }
                      }
                      biography {
                        json
                      }
                    }
                  }
                  startTime
                  endTime
                  hosts {
                    ... on ContentfulHost {
                      name
                      link
                      image {
                        fluid {
                          base64
                          sizes
                          src
                          srcSet
                          srcWebp
                          aspectRatio
                        }
                        fixed(width: 150, quality: 100) {
                          src
                          srcSet
                          srcSetWebp
                          srcWebp
                          height
                          width
                        }
                      }
                    }
                  }
                  info {
                    ... on ContentfulInfo {
                      title
                      description {
                        description
                      }
                    }
                  }
                  region {
                    ... on ContentfulRegion {
                      name
                    }
                  }
                  format {
                    ... on ContentfulFormat {
                      contentful_id
                      title
                    }
                  }
                  topic {
                    ... on ContentfulTopic {
                      id
                      contentful_id
                      name
                    }
                  }
                  imageGallery {
                    name
                    imageLandscape {
                      fluid {
                        base64
                        sizes
                        src
                        srcSet
                        srcWebp
                        aspectRatio
                      }
                    }
                  }
                  room {
                    ... on ContentfulRoom {
                      title
                    }
                  }
                }
              }
            }
          }
        }
      }
      location {
        title
      }
    }
  }
`;

export default PastForumsDetail;
