import moment from "moment";

export const getFormattedDate = (start, end) => {
  if (start === end) {
    return `${moment(end)
      .locale("en")
      .format("DD MMMM YYYY")}`;
  } else if (moment(start).format("MMMM") === moment(end).format("MMMM")) {
    return `${moment(start)
      .locale("en")
      .format("DD")} - ${moment(end)
      .locale("en")
      .format("DD MMMM YYYY")}`;
  } else {
    return `${moment(start)
      .locale("en")
      .format("DD MMMM")} - ${moment(end)
      .locale("en")
      .format("DD MMMM YYYY")}`;
  }
};
