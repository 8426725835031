/* Global imports */
import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { Accordion, Card } from 'react-bootstrap'
import { useLocation } from "@reach/router";
import moment from "moment";
import "moment/locale/de";

/* Local imports */
import SessionPreview from '../components/sessionPreview'
import { convertTZ } from '../helper/timezone'

/* Component definition */
const AgendaItem = ({id, title, startTime, endTime, label, sessions, withoutSessions=true, firstOpened=false}) => {
  const [itemOpen, setItemOpen] = useState(false);
  const [firstItemOpen, setFirstItemOpen] = useState(true)
  const location = useLocation();

  

  // Pass the times to a +00:00 Timezones Country to appear the corect hours
  const endTimeCorrected = convertTZ(endTime, 'Europe/Lisbon' );
  const startTimeCorrected = convertTZ(startTime, 'Europe/Lisbon' );
  const shouldShowTime = endTimeCorrected && startTimeCorrected;

  return (
    !withoutSessions ? (
      <Accordion
        key={id}
        defaultActiveKey={firstOpened ? id : false}
      >
        <Card>
          <Card.Header className="topCardHeader">
            <Accordion.Toggle
              as={Card.Header}
              variant="link"
              eventKey={id}
              className="cardHeaderInternal"
              onClick={() => firstOpened ? setFirstItemOpen(!firstItemOpen) : setItemOpen(!itemOpen)}
            >
              {/* {tab.label} */}
              <div className={`font-weight-bold ${shouldShowTime ? 'firstSection' : ''}`}>
                {!location.pathname.includes("/past-event/") && shouldShowTime && (
                  <div>{`${moment(startTimeCorrected).locale('en').format("h:mm a")} - ${moment(endTimeCorrected).locale('en').format("h:mm a")}`}</div>
                )}
                <div>{title}</div>
              </div>
              <div className="font-weight-bold">
                <span>
                  {!firstOpened ? (
                    <FontAwesomeIcon
                      icon={
                        !itemOpen
                          ? faPlusCircle
                          : faMinusCircle
                      }
                      size="lg"
                      className="agendaItemIcon"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={
                        !firstItemOpen
                          ? faPlusCircle
                          : faMinusCircle
                      }
                      size="lg"
                      className="agendaItemIcon"
                    />
                  )}
                  
                </span>
              </div>
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse
            eventKey={id}
          >
            <div className="cardBodyContainer">
              <Card.Body>
                <div className= {location.pathname.includes("/past-event/") ? "sessionsContainerPastEvent" : "sessionsContainer"}>
                  {sessions && (
                    sessions.map(
                      (session, index) => {
                        return(
                        <>
                          <SessionPreview
                            {...session}
                          />
                            {(index % 2 !== 0) && (!location.pathname.includes("/past-event/")) && (sessions.length -1 > index) ? 
                            (
                              <div className="even-bottom-line"></div>
                            ) : (
                              (index % 2 === 0) && (!location.pathname.includes("/past-event/")) && (sessions.length -1 > index) ? (
                                <div className=" hiddenLine"></div>
                              ) : (
                                null
                              )
                            )}
                        </>
                      )}
                    )
                  )}
                </div>
              </Card.Body>
            </div>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    ) : (
      <Accordion
        key={id}
        defaultActiveKey={false}
      >
        <Card>
          <Card.Header className="topCardHeader">
            <Accordion.Toggle
              as={Card.Header}
              variant="link"
              eventKey={id}
              className="cardHeaderInternal withoutSessionHeader"
            >
              {/* {tab.label} */}
              <div className={`font-weight-bold ${shouldShowTime ? 'firstSection' : ''}`}>
                {!location.pathname.includes("/past-event/") && shouldShowTime && (
                  <div>{`${moment(startTimeCorrected).locale('en').format("h:mm a")} - ${moment(endTimeCorrected).locale('en').format("h:mm a")}`}</div>
                )}
                <div>{title}</div>
              </div>
            </Accordion.Toggle>
          </Card.Header>
        </Card>
      </Accordion>
    )

    
  )
}
/* PropTypes */
AgendaItem.propTypes = {}
AgendaItem.defaultProps = {}

/* Local utility functions */

/* Styles */

export default AgendaItem