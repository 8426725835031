/* Global imports */
import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import { Col, Row, Container } from 'react-bootstrap';
import * as _ from 'lodash';

/* Local imports */
import Box from '../components/sidebar/box';
import Link from "../helper/link";

/* Component definition */
const Hosts = ({hosts, fullWidth=true}) => {
  const sortedHosts =  _.sortBy(hosts, "name")
  const hostItems = sortedHosts.map(host => {
    const {image, name} = host;
    const link = host.link
      ? host.link
      : "";
      
    return (
      fullWidth ? (
        <Col xs={6} sm={6} md={4} lg={3} xl={3}>
          <Link
            to={link}
            className={`icon-card `}
          >
            <div className="icon-card-inner">
              <div className="icon-card-content">
                <div className="top">
                  <Img
                    className="icon d-block w-100"
                    fluid={image.fluid}
                  />
                </div>
                <h6 className="title">{name}</h6>
              </div>
            </div>
          </Link>
        </Col>
      ) : (
        <Col xs={6} sm={6} md={6} lg={4} xl={4}>
          <Link
            to={link}
            className={`icon-card `}
          >
            <div className="icon-card-inner">
              <div className="icon-card-content">
                  <Img
                    className="icon d-block w-100"
                    fluid={image.fluid}
                  />
                <h6 className="title">{name}</h6>
              </div>
            </div>
          </Link>
        </Col>
      )
    )
  });

  return (
    <Container className="icon-cards p-0 pb-5">
      <Row className="d-flex justify-content-center">{hostItems}</Row>
    </Container>
  );
}
/* PropTypes */
Hosts.propTypes = {}
Hosts.defaultProps = {}

/* Local utility functions */

/* Styles */

export default Hosts