/* Global imports */
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {
  faArrowRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "@reach/router";
import { useStaticQuery } from 'gatsby';
import "moment/locale/de";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import slugify from 'slugify';
/* Local imports */
import TeaserCard from "../components/teaserCard";
import Link, { getFilteredSearchLink } from "../helper/link";
import { SLUGIFY_OPTIONS } from '../helper/slugify';
import { getValue } from "../helper/translation";
import Label from "./label";




/* Component definition */
const SessionPreview = ({ id, description: {description}="", name, endTime, startTime, mainTopic, secondaryTopic, format, gallery={}, imageGallery={}, hosts, info, moderators, reader, region, speakers, title,  subtitle, topic, room, node_locale, speakerDetail }) => {
  const { microcopy } = useStaticQuery(graphql`
    {
      microcopy: allContentfulMicrocopy {
        edges {
          node {
            node_locale
            key
            value
          }
        }
      }
    }
  `);

  const localizedMicrocopy = {};

  microcopy.edges
    .map(edge => edge.node)
    .forEach(node => {
      if (node.node_locale === node_locale) {
        localizedMicrocopy[node.key] = node.value;
      }
    });

  const location = useLocation();


  const getStringFromTagId = (tagId, topic) => {
    if (topic) {
      const relatedString = topic.find(top => top && top.contentful_id == tagId)
      return relatedString ? relatedString.name : ""
    }
    return null
  }

  const footer = (
    <div className="teaser-card-footer">
      {id && (
        <a href={`/session/${slugify(title, SLUGIFY_OPTIONS)}`} className="teaser-card-footer-link">
          {getValue("actions.readMore", localizedMicrocopy)}
          <FontAwesomeIcon
            icon={faArrowRight}
            size="lg"
            className="teaser-card-footer-icon"
          />
        </a>
      )}
      {/* {file && (
        <a
          href={file}
          rel="noreferrer"
          target="_blank"
          className="teaser-card-footer-link"
        >
          {downloadLabelText}
          <FontAwesomeIcon
            icon={faDownload}
            size="lg"
            className="teaser-card-footer-icon"
          />
        </a>
      )} */}
    </div>
  );


  return (

      !location.pathname.includes("/past-event/") ? (
        speakerDetail ? (
          <div className="sessionContainerSpeakerDetail">
            <article
              className={`teaser-card 
            `}
            >
              <Container className="p-0">
                <Row>
                  <Col
                    className="d-flex align-items-center"
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    
                      <div className="teaser-card-content">
                        <h3 className="teaser-card-title">{title}</h3>
                          <div className="teaser-card-topic">
                              {name && (<span>{name}</span>)}
                              {(name && mainTopic ) && (<span> | </span>)}
                              {mainTopic && (<span>{getStringFromTagId(mainTopic, topic)}</span>)}
                              {(mainTopic && room ) && (<span> | </span>)}
                              {room && (<span>{room.title}</span>)}
                          </div>
                          <div className="tags">
                            {format && (
                              <Label
                                text={format.title}
                                type="primary"
                                className="tag text-white font-weight-normal"
                                link={getFilteredSearchLink(
                                  format.contentful_id,
                                  "en-US"
                                  // tag.node_locale
                                )}
                              />
                            )}
                            {secondaryTopic && (
                              <Label
                                text={getStringFromTagId(secondaryTopic, [...topic, region])}
                                type="secondary"
                                className="tag"
                                link={getFilteredSearchLink(
                                  secondaryTopic,
                                  "en-US"
                                  // tag.node_locale
                                )}
                              />
                            )}
                          </div>

                        {reader && <p className="teaser-card-text">{documentToReactComponents(reader.json)}</p>}
                        {/* {footer} */}
                      </div>
                    
                  </Col>
                </Row>
              </Container>
              {footer}
            </article> 
          </div>
        ) : (
          <div className="sessionContainer">
            <article
              className={`teaser-card 
            
            `}
            >
              <Container className="p-0">
                <Row>
                  <Col style={{paddingLeft: 0}}
                    className="d-flex align-items-center"
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    
                      <div className="teaser-card-content">
                        <h3 className="teaser-card-title">{title}</h3>
                          <div className="tags">
                            {format && (
                              <Label
                                text={format.title}
                                type="primary"
                                className="tag text-white font-weight-normal"
                                link={getFilteredSearchLink(
                                  format.contentful_id,
                                  "en-US"
                                  // tag.node_locale
                                )}
                              />
                            )}
                            {secondaryTopic && (
                              <Label
                                // key={format.contentful_id}
                                text={getStringFromTagId(secondaryTopic, [...topic, region])}
                                type="secondary"
                                className="tag"
                                link={getFilteredSearchLink(
                                  secondaryTopic,
                                  "en-US"
                                  // tag.node_locale
                                )}
                              />
                            )}
                          </div>

                          <div className="teaser-card-topic">
                              {name && (<span>{name}</span>)}
                              {(name && mainTopic ) && (<span> | </span>)}
                              {mainTopic && (<span>{getStringFromTagId(mainTopic, topic)}</span>)}
                              {(mainTopic && room ) && (<span> | </span>)}
                              {room && (<span>{room.title}</span>)}
                          </div>

                        {hosts ? (
                          <p className="font-weight-bold session-card-subtitle">
                            Hosts: {hosts.map((host, index) => (
                              hosts.length - 1 !== index ? (
                                <span>{host.name}, </span>
                              ) : (
                                <span>{host.name}</span>
                              )
                            ))}
                          </p>
                        ):(
                          <p className="font-weight-bold session-card-subtitle"></p>
                        )}
                        {reader && <p className="teaser-card-text">{documentToReactComponents(reader.json)}</p>}
                        {/* {footer} */}
                      </div>
                    
                  </Col>
                </Row>
              </Container>
              {footer}
            </article> 
          </div>
        )
        
      ) : (
        <Link
          to={`/session/${slugify(title, SLUGIFY_OPTIONS)}`}
          className={`icon-card pastForumSessionContainer `}
        >
          <div className=" pastEvents mb-4">
            <Row>
                <Col
                  className="d-flex align-items-center pastForumSessionPreviewContainer "
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                    <TeaserCard 
                      // tags = {[{contentful_id: 1, name: "Name"}]}
                      title={`${title}`}
                      className="sessionTeaserCard bg-white"
                      articleType = {"true", format?.title }
                      link= "#"
                      image = {(imageGallery !== (undefined||null)) && (imageGallery.length > 0) ? imageGallery[0].imageLandscape.fluid : ""}
                    />
                  {/* <div className ="fillSpace"></div> */}
                </Col>
            </Row>
          </div>
        </Link>
      )
  );
};
/* PropTypes */
SessionPreview.propTypes = {};
SessionPreview.defaultProps = {};

/* Local utility functions */

/* Styles */

export default SessionPreview;
