/* Global imports */
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useStaticQuery } from "gatsby";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Teaser } from "../components/sidebar";
/* Local imports */
import Box from "../components/sidebar/box";
import Impressions from "../components/sidebar/impressions";
import TeaserCard from "../components/teaserCard";
import { getValue } from "../helper/translation";

/* Component definition */
const Summary = ({
  asideGalleryImages,
  infoBoxes,
  longDescription,
  title,
  video,
  node_locale,
}) => {
  const { microcopy } = useStaticQuery(graphql`
    {
      microcopy: allContentfulMicrocopy {
        edges {
          node {
            node_locale
            key
            value
          }
        }
      }
    }
  `);

  const localizedMicrocopy = {};
  microcopy.edges
    .map(edge => edge.node)
    .forEach(node => {
      if (node.node_locale === node_locale) {
        localizedMicrocopy[node.key] = node.value;
      }
    });

  const impressionsParams = {
    images: asideGalleryImages,
    summaryGallery: true,
  };
  return (
    <Container fluid className="summaryContainer">
      <Row className="sessionContent pt-5">
        <Col sm={12} md={12} lg={12} xl={12}>
          <article
            className={`teaser-card 
              `}
          >
            <Container className="p-0">
              {/* <Row noGutters={!big}> */}
              <Row>
                <Col
                  className="d-flex align-items-start "
                  xs={12}
                  sm={12}
                  md={12}
                  lg={8}
                  xl={8}
                  // md={landscape ? (big ? 4 : isOverview ? 9 : 6) : 12}
                  // lg={landscape ? (big ? 4 : isOverview ? 9 : 8) : 12}
                  // xl={landscape ? (big ? 4 : isOverview ? 9 : 8) : 12}
                >
                  <div className="d-flex flex-column">
                    <p>{documentToReactComponents(longDescription.json)}</p>
                    {video && (
                      <TeaserCard
                        video={video.embedLink}
                        image={video.thumbnailLandscape.imageLandscape.fluid}
                      />
                    )}
                  </div>
                </Col>
                <Col sm={12} md={12} lg={4} xl={4} className="sidebarContainer">
                  <div className="sidebarInsideContainer">
                    {infoBoxes &&
                      (infoBoxes.length > 1 ? (
                        infoBoxes.map((infoBox, index) => {
                          return index !== 1 ? (
                            infoBox.__typename === "ContentfulInfo" &&
                            infoBox.description ? (
                              <Box
                                headline={`${infoBox.title}`}
                                className="sidebarBox"
                              >
                                <div className="infoBox">
                                  <div>{infoBox.description.description}</div>
                                </div>
                              </Box>
                            ) : (
                              <Teaser
                                title={infoBox.title}
                                content={documentToReactComponents(
                                  infoBox.body.json
                                )}
                                link={
                                  infoBox.link
                                    ? infoBox.link.url
                                    : infoBox.internalLink.slug
                                }
                                // locale={article.node_locale}
                              />
                            )
                          ) : (
                            <>
                              <Impressions {...impressionsParams} />
                              {infoBox.__typename === "ContentfulInfo" &&
                              infoBox.description ? (
                                <Box
                                  headline={`${infoBox.title}`}
                                  className="sidebarBox"
                                >
                                  <div className="infoBox">
                                    <div>{infoBox.description.description}</div>
                                  </div>
                                </Box>
                              ) : (
                                <Teaser
                                  title={infoBox.title}
                                  content={documentToReactComponents(
                                    infoBox.body.json
                                  )}
                                  link={
                                    infoBox.link
                                      ? infoBox.link.url
                                      : `/${infoBox.internalLink.slug}`
                                  }
                                  btnText={
                                    infoBox.link
                                      ? infoBox.link.linkText
                                      : getValue(
                                          `actions.view${infoBox.internalLink.slug}`,
                                          localizedMicrocopy
                                        )
                                  }
                                  // locale={article.node_locale}
                                />
                              )}
                              {/* <Box headline={`${infoBox.title}`} className="sidebarBox">
                                  <div className="infoBox">
                                    <div>{infoBox.description.description}</div>
                                  </div>
                                </Box> */}
                            </>
                          );
                        })
                      ) : (
                        <>
                          {infoBoxes.map((infoBox, index) => {
                            return infoBox.description ? (
                              <Box
                                headline={`${infoBox.title}`}
                                className="sidebarBox"
                              >
                                <div className="infoBox">
                                  <div>{infoBox.description.description}</div>
                                </div>
                              </Box>
                            ) : (
                              <Teaser
                                title={infoBox.title}
                                content={documentToReactComponents(
                                  infoBox.body.json
                                )}
                                link={
                                  infoBox.link
                                    ? infoBox.link.url
                                    : infoBox.internalLink &&
                                      infoBox.internalLink.slug
                                }
                                // locale={article.node_locale}
                              />
                            );
                          })}
                          <Impressions {...impressionsParams} />
                        </>
                      ))}
                  </div>
                </Col>
              </Row>
            </Container>
          </article>
        </Col>
      </Row>
    </Container>
  );
};
/* PropTypes */
Summary.propTypes = {};
Summary.defaultProps = {};

/* Local utility functions */

/* Styles */

export default Summary;
