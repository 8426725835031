/* Global imports */
import React from "react";
import PropTypes from "prop-types";

/* Local imports */
import Box from "./box";
import Link from "../../helper/link";

/* Component definition */
const Teaser = ({ title, content, link, btnText="" }) => (
  <Box headline={title}>
    <p className="content">{content}</p>
    {link && (
      <div className="btnContainer">
        <Link className="button button--inverted button--link" to={link} >
          {btnText}
        </Link>
      </div>
    )}
  </Box>
);

/* PropTypes */
Teaser.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  link: PropTypes.string,
};
Teaser.defaultProps = {
  title: "Title",
  content:
    "FriEnt beschäftigt sich seit 2008 mit dem Thema Landkonflikte. Die Aktivitäten von FriEnt konzentrieren sich darauf, die Verbindung zwischen verschiedenen Handlungsfeldern der Entwicklungszusammenarbeit im Bereich der ländlichen Entwicklung zu dem Konfliktkontext aufzuzeigen und Potentiale für Konfliktbearbeitung und Krisenprävention herauszuarbeiten. Der Gestaltung gemeinsamer Lernprozesse und Vernetzung kommt dabei besondere Bedeutung zu.",
};

/* Local utility functions */

/* Styles */

export default Teaser;
