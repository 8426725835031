/* Global imports */
import PropTypes from "prop-types";
import React from "react";
import Gallery from "../gallery";
/* Local imports */
import Box from "./box";
import {getValue} from '../../helper/translation'

/* Component definition */
const Impressions = ({ images, locale, summaryGallery }) => {
  return (
    !summaryGallery ? (
      <Box
        headline={getValue("labels.impressions", locale)}
        className="d-flex impressions"
        locale={locale}
      >
        <Gallery images={images} summaryGallery={false}/>
      </Box>
    ) : (
      <Box
        headline="labels.impressions"
        className="d-flex impressions"
        locale={locale}
      >
        <Gallery images={images} summaryGallery={summaryGallery}/>
      </Box>
    )
      
  );
};
/* PropTypes */
Impressions.propTypes = {
  locale: PropTypes.oneOf(["en-US"]),
};
Impressions.defaultProps = {
  images: [],
  locale: "en-US",
};

/* Local utility functions */

/* Styles */

export default Impressions;
